import { BaseViewModel } from "./BaseViewModel";
import { BaseState } from "./BaseState";
import { DashboardRepository } from "../domain/repository/DashboardRepository";
import { ProjectInfo } from "../domain/model/ProjectInfo";
import moment from "moment";
import { ValidationUtils } from "../core/ValidationUtils";
export interface IProjectState {
  isLoading: boolean;
  totalCount: number;
  pageSize: number;
  pageIndex: number;
  projects: ProjectInfo[];
  sortBy: string;
  orderBy: boolean;
  filterKey: string;
  fromDate?: Date | undefined;
  toDate?: Date | undefined;
  fromDateString?: string;
  toDateString?: string;
}

export interface IProjectProps {}

export class ProjectViewModel extends BaseViewModel {
  protected state: IProjectState;
  constructor(
    private baseState: BaseState,
    private dashboardRepository: DashboardRepository
  ) {
    super();
    this.state = this.defaultState();
  }

  protected setState(newState: IProjectState) {
    this.state = newState;
    this.trigger();
  }

  defaultState(): IProjectState {
    return {
      isLoading: false,
      totalCount: 0,
      pageSize: 25,
      pageIndex: 0,
      projects: [],
      sortBy: "projectname",
      orderBy: true,
      filterKey: "All",
      fromDate: undefined,
      toDate: undefined,
      fromDateString: "",
      toDateString: "",
    };
  }

  public async load(): Promise<void> {
    try {
      const response = await this.dashboardRepository.getProjects(
        this.state.pageIndex,
        this.state.pageSize,
        this.state.sortBy,
        this.state.orderBy,
        this.state.filterKey,
        this.state.fromDateString!,
        this.state.toDateString!
      );

      response.body.data.forEach((element: any) => {
        element.amount = ValidationUtils.convertToIndianStandardCurrency(
          "" + (element.amount > 0 ? element.amount : 0)
        );
        element.totalprojectvalue =
          ValidationUtils.convertToIndianStandardCurrency(
            "" +
              (element.totalprojectvalue > 0
                ? element.totalprojectvalue.toFixed(2)
                : 0)
          );
        element.achievedrevenuevalue =
          ValidationUtils.convertToIndianStandardCurrency(
            "" +
              (element.achievedrevenuevalue > 0
                ? element.achievedrevenuevalue.toFixed(2)
                : 0)
          );
        element.signupdate = element.signupdate
          ? moment(element.signupdate).format("D MMMM Y")
          : "-";
        element.startDate = element.startDate
          ? moment(element.startDate).format("D MMMM Y")
          : "-";
        element.endDate = element.endDate
          ? moment(element.endDate).format("D MMMM Y")
          : "-";
      });
      this.setMany({
        ...this.state,
        projects: response.body.data,
        totalCount: response.body.data?.filter(
          (e: any) => e?.projectstatus?.toLowerCase() !== "draft"
        )?.length,
      });
    } catch (error) {
      this.baseState.set("isLoading", false);
    }
  }
}
